<template>
  <div>
    <PageTitle
      title="追蹤連結列表"
      btn="新增"
      :btnLoading="createLiffLoading"
      @btnClick="createTrackingLink"
    />
    <div v-loading="loading">
      <el-table :data="tableData">
        <EmptyBlock slot="empty" />
        <el-table-column label="連結名稱" prop="name" align="center" />
        <el-table-column label="追蹤連結" prop="shortLink" align="center">
          <template slot-scope="scope">
            <div class="flex items-center" style="gap: 10px">
              <p class="w-full">{{ scope.row.shortLink }}</p>
              <span
                class="material-icons text-primary-100 cursor-pointer"
                style="width: 24px; transform: scaleX(-1)"
                @click="copyLink(scope.row.shortLink)"
              >
                content_copy_outlined
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="標籤" align="center">
          <template slot-scope="scope">
            <div class="flex flex-wrap" style="gap: 8px">
              <Tag v-for="tag in tagList(scope.row)" :key="tag" type="action">{{ tag }}</Tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="開始時間" prop="start" width="170px" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.start) }}
          </template>
        </el-table-column>
        <el-table-column label="結束時間" prop="end" width="170px" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.end) }}
          </template>
        </el-table-column>
        <el-table-column label="連結狀態" prop="status" align="center" width="100px">
          <template slot-scope="scope">
            <Tag :type="get(linkStatus(scope.row), 'type')">
              {{ get(linkStatus(scope.row), 'label') }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column label="點擊次數" prop="viewCount" align="center" width="100px">
          <!-- <template slot-scope="scope">
            <el-button v-if="scope.row.viewCount === null" type="text" @click="viewCount(scope.row)">計算</el-button>
            <div v-else class="text-center">{{ scope.row.viewCount }}</div>
          </template> -->
        </el-table-column>
        <el-table-column label="操作" prop="status" fixed="right" width="100px" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="editTrackingLink(scope.row)"
              @delete="hadnleDelete(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="refresh"
    />

    <DeleteDialog
      v-if="dialog.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="dialog.delete = false"
      @delete="deleteTrackingLink"
    />
  </div>
</template>

<script>
import { GetTrackingLink, GetTrackingLinkCount, DeleteTrackingLink, FindViewCount } from '@/api/trackingLink'
import { GetLiff, CreateLiff } from '@/api/shop'
import tableMixin from '@/mixin/table'
import { find, get, map } from 'lodash'
import dayjs from '@/lib/dayjs'
import copy from 'clipboard-copy'

export default {
  name: 'TrackingLinkManagement',
  mixins: [tableMixin],
  data: () => ({
    tableData: [],
    needCreateLiff: false,
    createLiffLoading: false,
    target: null,
    dialog: {
      delete: false,
    },
  }),
  async mounted () {
    this.checkLiff()
    await this.refresh()
  },
  async activated () {
    await this.refresh()
  },
  methods: {
    get,
    copyLink (link) {
      copy(link)
      this.$message.success('複製連結成功！')
    },
    tagList (row) {
      const register = row.RegisterMTags
      const action = row.ActionMTags
      let count = 0
      const list = []
      if (register.length) {
        list.push(get(register, '[0].name'))
        if (register.length - 1) count += register.length - 1
      }
      if (action.length) {
        list.push(get(action, '[0].name'))
        if (action.length - 1) count += action.length - 1
      }
      if (count) list.push(`+${count}`)
      return list
    },
    async editTrackingLink (row) {
      this.$router.push({
        name: 'EditTrackingLink',
        params: {
          id: row.id,
        },
      })
    },
    async hadnleDelete (row) {
      this.target = row
      this.dialog.delete = true
    },
    async deleteTrackingLink () {
      const [, err] = await DeleteTrackingLink({
        shopId: this.shop,
        id: this.target.id,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('刪除成功！')
      this.dialog.delete = false
      await this.refresh()
    },
    async refresh () {
      this.loading = true
      await this.getTableDataCount()
      await this.getTableData()
      const ids = map(this.tableData, 'id')
      if (ids.length) await this.findViewCount(ids)
      this.loading = false
    },
    async getTableDataCount () {
      const [res, err] = await GetTrackingLinkCount({ shopId: this.shop })
      if (err) {
        this.$message.error(err.msg || err)
        return
      }
      this.tableOptions.dataCount = res.count
    },
    async getTableData () {
      const [res, err] = await GetTrackingLink({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
      })
      if (err) {
        this.$message.error(err.msg || err)
        return
      }
      res.forEach(i => {
        i.viewCount = 0
      })
      this.tableData = res
    },
    async checkLiff () {
      const res = await GetLiff({ shopId: this.shop })
      const exist = find(res, { name: 'trackingLink' })
      if (!exist) this.needCreateLiff = true
    },
    async createLiff () {
      try {
        await CreateLiff({
          shopId: this.shop,
          name: 'trackingLink',
        })
      } catch (err) {
        this.$message.error(err)
        return false
      }
      return true
    },
    async createTrackingLink () {
      this.createLiffLoading = true
      if (this.needCreateLiff) {
        if (!await this.createLiff()) {
          this.createLiffLoading = false
          return
        }
      }
      this.$router.push({
        name: 'EditTrackingLink',
      })
      this.createLiffLoading = false
    },

    async findViewCount (ids) {
      const [res, err] = await FindViewCount({
        shopId: this.shop,
        ids: ids.join(','),
      })
      if (err) {
        this.$message.error(err)
      }
      // const target = find(this.tableData, { id: row.id })

      res.forEach(i => {
        const target = find(this.tableData, { id: i.id })
        target.viewCount = i.viewCount
      })
      // target.viewCount = res.viewCount
    },

    linkStatus (row) {
      const start = dayjs(row.start)
      const end = dayjs(row.end)
      const now = dayjs()
      if (now.isAfter(end)) {
        return {
          type: 'info',
          label: '已結束',
        }
      }
      if (now.isAfter(start) && now.isBefore(end)) {
        return {
          type: 'warning',
          label: '進行中',
        }
      }
      if (now.isBefore(start)) {
        console.log('here')
        return {
          type: 'action',
          label: '已排程',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
